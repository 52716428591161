import React, {
  useContext,
  useState,
  useEffect,
  useReducer,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../../context/globalContext';
import ServiceAPI from '../../services';
import {
  login,
  loginEmail,
  loginPassword,
  loginRememberMe,
  loginError
} from '../../actions';
import reducer, { initialState } from '../../reducers/login';
import { LoginPagesContainer } from '../StyleComponents';
import MainButton from '../Buttons/MainButton';
import { ReactComponent as IconEmail } from '../../img/sprite/svg/email.svg';
import { ReactComponent as IconLock } from '../../img/sprite/svg/lock.svg';
import { ReactComponent as IconEye } from '../../img/sprite/svg/eye.svg';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

function Login() {
  const { dispatchGlobal } = useContext(GlobalContext);
  const service = new ServiceAPI();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  let currentLang = i18n.language;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const validateEmailPattern = (value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  };

  const validateEmailField = (value) => {
    dispatch(
      loginError({
        requiredEmail: !value,
        emailPattern: !validateEmailPattern(value)
      })
    );
  };

  const validatePasswordField = (value) => {
    dispatch(
      loginError({
        requiredPassword: !value
      })
    );
  };

  const validateFields = () => {
    validateEmailField(state.email.value);
    validatePasswordField(state.password.value);
    return (
      !(
        state.errors.requiredEmail ||
        state.errors.emailPattern ||
        state.errors.requiredPassword
      ) &&
      state.email.value &&
      state.password.value
    );
  };

  const handlerOnChangeField = (e, func) => {
    dispatch(func(e));
    dispatch(
      loginError({
        emailOrPasswordNotMath: false
      })
    );
  };

  const handleSubmit = () => {
    if (!validateFields()) return false;
    service
      .login({ email: state.email.value, password: state.password.value })
      .then((result) => {
        if (result.status === 200) {
          dispatchGlobal(
            login(
              state.rememberMe ? result.data : { ...result.data, refresh: '' }
            )
          );
          history.push('/');
        } else {
          dispatch(
            loginError({
              emailOrPasswordNotMath: true
            })
          );
        }
      });
  };

  const loginComponent = <>
    <h1 className="login-form__title">{t('LoginPage.pageName')}</h1>
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="form-group mb-4">
        <label htmlFor="email">
          <span className="required-label">*</span>
          Email
        </label>
        <div className="form-control-wrapper">
          <input
            ref={inputRef}
            className={
              state.email.touched &&
              !state.errors.requiredEmail &&
              !state.errors.emailPattern &&
              !state.errors.emailOrPasswordNotMath
                ? 'form-control form-control-email is-valid'
                : !state.email.touched &&
                  !state.errors.requiredEmail &&
                  !state.errors.emailPattern &&
                  !state.errors.emailOrPasswordNotMath
                ? 'form-control form-control-email'
                : 'form-control form-control-email is-invalid'
            }
            id="email"
            type="text"
            name="email"
            onChange={(e) => {
              handlerOnChangeField(e.target.value, loginEmail);
              validateEmailField(e.target.value);
            }}
            value={state.email.value}
          />
          <IconEmail className="svg-sprite-icon icon-email" />
        </div>
        {state.errors.requiredEmail && (
          <div className="error">
            <span>{t('ERROR_TEXT.requiredEmail')}</span>
          </div>
        )}
        {state.errors.emailPattern && !state.errors.requiredEmail && (
          <div className="error">
            <span>{t('ERROR_TEXT.validEmail')}</span>
          </div>
        )}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="password">
          <span className="required-label">*</span>
          {t('LoginPage.password')}
        </label>
        <div className="form-control-wrapper">
          <input
            autoComplete="off"
            className={
              state.password.touched &&
              !state.errors.requiredPassword &&
              !state.errors.emailOrPasswordNotMath
                ? 'form-control form-control-password is-valid'
                : !state.password.touched &&
                  !state.errors.requiredPassword &&
                  !state.errors.emailOrPasswordNotMath
                ? 'form-control form-control-password'
                : 'form-control form-control-password is-invalid'
            }
            id="password"
            type={visiblePassword ? 'text' : 'password'}
            name="password"
            value={state.password.value}
            onChange={(e) => {
              handlerOnChangeField(e.target.value, loginPassword);
              validatePasswordField(e.target.value);
            }}
          />
          <IconLock className="svg-sprite-icon icon-lock" />
          <span
            className={
              visiblePassword
                ? 'toggle-password toggle-password-visible'
                : 'toggle-password'
            }
            toggle="#password"
          >
            <IconEye
              className="svg-sprite-icon icon-eye"
              onClick={() => {
                setVisiblePassword(!visiblePassword);
              }}
            />
          </span>
        </div>
        {state.errors.requiredPassword && (
          <div className="error">
            <span>{t('ERROR_TEXT.passwordRequired')}</span>
          </div>
        )}
        {state.errors.emailOrPasswordNotMath && (
          <div className="error">
            <span>{t('ERROR_TEXT.badCredentials')}</span>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div className="form-group form-check d-flex align-items-center">
          <input
            className="form-check-input mt-0"
            id="rememberMe"
            type="checkbox"
            name="rememberMe"
            onChange={(e) => {
              handlerOnChangeField(e.target.checked, loginRememberMe);
            }}
            value={state.rememberMe}
          />
          <label className="form-check-label" htmlFor="rememberMe">
            {t('LoginPage.rememberMe')}
          </label>
        </div>
        <div className="form-group">
          <a
            onClick={() => {
              setForgotPassword(!forgotPassword);
            }}
          >
            {t('LoginPage.forgotPassword')}
          </a>
        </div>
      </div>
      <div className="form-group mb-0">
        <MainButton
          type="submit"
          className="btn btn-confirm d-block w-100"
          text={t('LoginPage.enter')}
        />
      </div>
    </form>
  </>

  const dontHaveAccountComponent = currentLang === 'en' ? (
    <div className="login-form__container login-form__container--demo-container mt-5">
      <h1 className="login-form__demo">Don't have an account yet?</h1>
      <div className="form-group mb-0">
        <MainButton
          type="button"
          className="btn btn-demo d-block w-100 demo-request-button"
          text='Request a demo'
        />
      </div>
    </div>
  ) : null;

  if (!forgotPassword) {
    return (
      <LoginPagesContainer
        child1={loginComponent}
        child2={dontHaveAccountComponent}
      />  
    );
  }
  return <ForgotPassword validateEmailPattern={validateEmailPattern} />;
}

export default Login;
