import React from 'react';
import Reviews from '../components/Reviews/Reviews';
import DefaultInner from './DefaultInner';

const ReviewsPage = () => {
  return (
    <DefaultInner>
      <Reviews />
    </DefaultInner>
  );
};

export default ReviewsPage;
