import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Modal,
  Card,
  Row,
  Col,
  Image,
  Form,
  InputGroup,
  Button
} from 'react-bootstrap';
import googleTextLogo from '../../img/google-text-logo.png';
import html2canvas from 'html2canvas';

const canvasSizes = [
  {
    value: 0,
    label: 'Stories and Reels: Instagram / Facebook / TikTok (1080 x 1920px)'
  },
  {
    value: 1,
    label: 'Square post: Instagram / TikTok / LinkedIn(1080 x 1080 px)'
  },
  { value: 2, label: 'Square post: Facebook ( 1200 x 1200 px)' }
];

const canvasSizesWidthHeight = {
  0: [1080, 1920],
  1: [1080, 1080],
  2: [1200, 1200]
};

const aspectRatios = {
  0: '9 / 16',
  1: '1 / 1',
  2: '1 / 1'
};

const fontStyles = [
  'Roboto',
  'Montserrat',
  'Open Sans',
  'Oswald',
  'Martian Mono',
  'PT Sans',
  'PT Sans Caption',
  'Sofia Sans',
  'Victor Mono',
  'Raleway'
];

const ReviewDownloadModal = ({
  show,
  onHide,
  author,
  rating,
  date,
  snippet,
  backgroundColor,
  firstLetter
}) => {
  const { t } = useTranslation();
  const [fontSize, setFontSize] = useState(14);
  const [fontStyle, setFontStyle] = useState(fontStyles[0]);
  const [colorCode, setColorCode] = useState('4285f4');
  const [canvasSize, setCanvasSize] = useState(canvasSizes[0]);

  const downloadRef = useRef();

  useEffect(() => {
    if (!document.getElementById(fontStyles[0]?.replace(/ /g, '+'))) {
      fontStyles.forEach((item) => {
        const nameForLink = item.replace(/ /g, '+');
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css2?family=${nameForLink}&display=swap`;
        link.rel = 'stylesheet';
        link.id = nameForLink;

        document.head.appendChild(link);
      });
    }
  }, []);

  const handleCanvasSizeChange = (e) => {
    const selectedValue = e.target.value;

    const selectedItem = canvasSizes.find(
      (item) => item.value === parseInt(selectedValue)
    );

    setCanvasSize(selectedItem);
  };

  const handleFontSizeChange = (e) => {
    setFontStyle(e.target.value);
  };

  const handleColorChange = (e) => {
    const value = e.target.value;
    if (/^[0-9a-fA-F]{0,6}$/.test(value)) {
      setColorCode(value);
    }
  };

  const handleDownload = () => {
    const width = canvasSizesWidthHeight[canvasSize.value][0];
    const height = canvasSizesWidthHeight[canvasSize.value][1];
    if (downloadRef.current) {
      html2canvas(downloadRef.current, {
        scale: 4,
        backgroundColor: `#${colorCode}`
      })
        .then((canvas) => {
          const resizedCanvas = document.createElement('canvas');
          resizedCanvas.width = width;
          resizedCanvas.height = height;

          const context = resizedCanvas.getContext('2d');

          // Calculate scaling and positioning to maintain proportions
          const originalWidth = canvas.width;
          const originalHeight = canvas.height;
          const scale = Math.min(
            width / originalWidth,
            height / originalHeight
          );

          const scaledWidth = Math.ceil(originalWidth * scale);
          const scaledHeight = Math.ceil(originalHeight * scale);
          const offsetX = Math.round((width - scaledWidth) / 2);
          const offsetY = Math.round((height - scaledHeight) / 2);

          // Fill the background with white (optional)
          context.fillStyle = `#${colorCode}`;
          context.fillRect(0, 0, width + 1, height + 1);

          // Draw the scaled content
          context.drawImage(
            canvas,
            0,
            0,
            originalWidth,
            originalHeight,
            offsetX,
            offsetY,
            scaledWidth,
            scaledHeight
          );

          // Create a download link
          const link = document.createElement('a');
          link.download = 'Liketome.png';
          link.href = resizedCanvas.toDataURL('image/png');
          link.click();
        })
        .catch((err) => {
          console.error('Error rendering to PNG:', err);
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <div className="modal-header text-center review-modal-header">
        {t('ReviewsPage.mediaPostGen')}
        <button
          className="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => onHide()}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="divider-container">
        <div className="content-section">
          <div className="left-section">
            <Form className="canvas-settings p-3">
              {/* Canvas Size */}
              <Form.Group as={Row} className="setting-line">
                <Form.Label column sm="12" className="label-text">
                  {t('ReviewsPage.canvasSize')}
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    as="select"
                    value={canvasSize.value}
                    onChange={handleCanvasSizeChange}
                  >
                    {canvasSizes.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>

              {/* Font Style */}
              <Form.Group as={Row} className="setting-line">
                <Form.Label column sm="12" className="label-text">
                  {t('ReviewsPage.fontStyle')}
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    as="select"
                    value={fontStyle}
                    onChange={handleFontSizeChange}
                  >
                    {fontStyles.map((item) => {
                      return <option key={item}>{item}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>

              {/* Font Size */}
              <Form.Group as={Row} className="setting-line">
                <Form.Label column sm="12" className="label-text">
                  {t('ReviewsPage.fontSize')}
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    type="range"
                    min="7"
                    max="30"
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                  />
                  <div className="slider-value">{fontSize}px</div>
                </Col>
              </Form.Group>

              {/* Background Color */}
              <Form.Group as={Row} className="setting-line">
                <Form.Label column sm="12" className="label-text">
                  {t('ReviewsPage.backgroundColor')}
                </Form.Label>
                <Col sm="12">
                  <div className="color-circles">
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#ffffff' }}
                      onClick={() => setColorCode('ffffff')}
                    ></div>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#212121' }}
                      onClick={() => setColorCode('212121')}
                    ></div>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#8f2af5' }}
                      onClick={() => setColorCode('8f2af5')}
                    ></div>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#34a853' }}
                      onClick={() => setColorCode('34a853')}
                    ></div>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#4285f4' }}
                      onClick={() => setColorCode('4285f4')}
                    ></div>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#fbbc05' }}
                      onClick={() => setColorCode('fbbc05')}
                    ></div>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#ea4335' }}
                      onClick={() => setColorCode('ea4335')}
                    ></div>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#f530dd' }}
                      onClick={() => setColorCode('f530dd')}
                    ></div>
                    <div
                      className="color-circle"
                      style={{ backgroundColor: '#e0e0e0' }}
                      onClick={() => setColorCode('e0e0e0')}
                    ></div>
                    <div className="custom-color-container">
                      <InputGroup>
                        <InputGroup.Text className="hash-symbol">
                          #
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          maxLength="6"
                          aria-label="Color Code"
                          value={colorCode}
                          onChange={handleColorChange}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="mt-3 d-flex justify-content-center">
                    <Button
                      variant="light"
                      className="border download-review-button"
                      onClick={handleDownload}
                    >
                      {t('ReviewsPage.download')} PNG
                    </Button>
                  </div>
                </Col>
              </Form.Group>
            </Form>
          </div>
          <div
            className="right-section"
            style={{
              backgroundColor: `#${colorCode}`,
              aspectRatio: aspectRatios[canvasSize.value]
            }}
            ref={downloadRef}
          >
            <div className="review-card-wrapper">
              <Card className="review-card review-card-modal">
                <Card.Body className="pb-0">
                  <Row className="align-items-center mb-1">
                    <Col xs="auto" className="pr-0">
                      <div
                        className="circle text-white d-flex align-items-center justify-content-center"
                        style={{ backgroundColor }}
                      >
                        {firstLetter}
                      </div>
                    </Col>
                    <Col>
                      <Card.Title className="mb-0">{author}</Card.Title>
                    </Col>
                  </Row>

                  <Row className="mb-1">
                    <Col>
                      {[1, 2, 3, 4, 5].map((_, index) => (
                        <i
                          key={index}
                          className={`bi bi-star${
                            index < rating ? '-fill' : ''
                          } text-warning`}
                        ></i>
                      ))}
                    </Col>
                  </Row>

                  <Row className="mb-1">
                    <Col>
                      <Card.Text
                        style={{
                          fontSize: `${fontSize}px`,
                          fontFamily: fontStyle
                        }}
                      >
                        {snippet}
                      </Card.Text>
                    </Col>
                  </Row>

                  {date && (
                    <Row className="mb-1 text-muted">
                      <Col>{date}</Col>
                    </Row>
                  )}

                  <Row className="mt-auto text-center">
                    <Col>
                      <span>Verified by</span>
                      <Image
                        src={googleTextLogo}
                        alt="Google logo"
                        className="google-logo-modal"
                      />
                      <span>reviews</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReviewDownloadModal;

ReviewDownloadModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  author: PropTypes.string,
  rating: PropTypes.string,
  date: PropTypes.string,
  snippet: PropTypes.string,
  backgroundColor: PropTypes.string,
  firstLetter: PropTypes.string
};

ReviewDownloadModal.defaultProps = {
  show: false,
  onHide: null,
  author: '',
  rating: '5',
  date: '',
  snippet: '',
  backgroundColor: '#ffffff',
  firstLetter: ''
};
