import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    border: 'solid 1px #e3e7ee',
    borderRadius: '3px',
    minHeight: 'unset',
    height: 'auto',
    cursor: 'pointer',
    boxShadow: 'none',
    padding: '4px',
    ':hover': {
      border: 'solid 1px #e3e7ee',
      boxShadow: 'none'
    },
    ':focus-within': {
      border: 'solid 1px e3e7ee',
      boxShadow: '0 0 0 1.5px rgba(116, 173, 230, 0.25)'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px'
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px'
  })
};

const starOptions = [
  { value: 4, label: 4 },
  { value: 5, label: 5 }
];

const renderStars = (rating) => {
  return (
    <span>
      {'★'
        .repeat(rating)
        .split('')
        .map((_, index) => (
          <i key={index} className="bi bi-star-fill text-warning"></i>
        ))}
    </span>
  );
};

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ padding: '10px', fontSize: '1.2rem', cursor: 'pointer' }}
    >
      {renderStars(data.label)}
    </div>
  );
};

const CustomSingleValue = ({ data }) => (
  <div style={{ fontSize: '0.875rem' }}>
    Rating stars:{' '}
    <span
      style={{
        backgroundColor: 'green',
        color: 'white',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        padding: '0px 5px',
        borderRadius: '4px'
      }}
    >
      {data.value}
    </span>
  </div>
);

const FilterByStars = ({ selectedStars, handleStarClick }) => {
  return (
    <div className="content__header-stars">
      <Select
        options={starOptions}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        defaultValue={starOptions[0]}
        styles={customStyles}
        isSearchable={false}
        onChange={handleStarClick}
        value={starOptions.filter((item) => item.value === selectedStars)}
      />
    </div>
  );
};

export default FilterByStars;
