import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import GlobalContext from '../../context/globalContext';
import { logOut } from '../../actions';
import { ReactComponent as IconProfile } from '../../img/sprite/svg/profile.svg';
import { ReactComponent as IconAdmin } from '../../img/sprite/svg/admin.svg';
import { ReactComponent as IconLogout } from '../../img/sprite/svg/logout.svg';
import { ReactComponent as IconStatistics } from '../../img/sprite/svg/statistics.svg';
import { ReactComponent as IconRating } from '../../img/sprite/svg/rating.svg';
import { ReactComponent as IconPolls } from '../../img/sprite/svg/polls.svg';
import { ReactComponent as IconDevices } from '../../img/sprite/svg/devices.svg';
import { ReactComponent as IconReports } from '../../img/sprite/svg/reports.svg';
import { ReactComponent as IconReviews } from '../../img/sprite/svg/reviews.svg';
import { ReactComponent as IconHelp } from '../../img/sprite/svg/help.svg';

const UserMenuPopUpWindow = ({ userInitials, setSettingsIsOpen }) => {
  const { stateGlobal, dispatchGlobal } = useContext(GlobalContext);
  const { user } = stateGlobal;
  const userHasHardware = user?.firm?.has_hardware;
  const { t } = useTranslation();
  const userIsOwnerOrAdmin = user?.is_owner || user?.is_admin;

  const logoutHandler = () => {
    dispatchGlobal(logOut());
  };

  return (
    <nav className="menu">
      <ul className="menu__list">
        <li className="menu__item menu__item--user-data">
          <ul className="user-data">
            <li className="user-data__avatar">
              <span className="user-avatar">{userInitials || '??'}</span>
            </li>
            <li className="user-data__info">
              <span className="user-data__info-name">
                {`${user.first_name || ''} ${user.last_name || ''}`}
              </span>
              <span className="user-data__info-mail">{user.email || ''}</span>
            </li>
          </ul>
        </li>
        <li className="menu__item d-lg-none">
          <NavLink
            exact
            to="/"
            onClick={() => setSettingsIsOpen(false)}
            className="menu__link menu__link--statistics"
            activeClassName="is-active"
          >
            <IconStatistics className="svg-sprite-icon icon-statistics" />
            {t('statisticPage.pageName')}
          </NavLink>
        </li>
        <li className="menu__item d-lg-none">
          <NavLink
            exact
            to="/rating"
            onClick={() => setSettingsIsOpen(false)}
            className="menu__link menu__link--rating"
            activeClassName="is-active"
          >
            <IconRating className="svg-sprite-icon icon-rating" />
            {t('ratingPage.pageName')}
          </NavLink>
        </li>
        <li className="menu__item d-lg-none">
          <NavLink
            exact
            to="/polls"
            onClick={() => setSettingsIsOpen(false)}
            className="menu__link menu__link--polls"
            activeClassName="is-active"
          >
            <IconPolls className="svg-sprite-icon icon-polls" />
            {t('pollsPage.pageName')}
          </NavLink>
        </li>
        {userHasHardware && (
          <li className="menu__item d-lg-none">
            <NavLink
              exact
              to="/devices"
              className="menu__link menu__link--devices"
              onClick={() => setSettingsIsOpen(false)}
              activeClassName="is-active"
            >
              <IconDevices className="svg-sprite-icon icon-devices" />
              {t('devicesPage.pageName')}
            </NavLink>
          </li>
        )}
        <li className="menu__item d-lg-none">
          <span className="main-nav__new">New!</span>
          <NavLink
            exact
            to="/reviews"
            onClick={() => setSettingsIsOpen(false)}
            className="menu__link menu__link--rewiews"
            activeClassName="is-active"
          >
            <IconReviews className="svg-sprite-icon icon-reviews" />
            {t('ReviewsPage.pageName')}
          </NavLink>
        </li>
        <li className="menu__item d-lg-none">
          <NavLink
            exact
            to="/reports"
            onClick={() => setSettingsIsOpen(false)}
            className="menu__link menu__link--reports"
            activeClassName="is-active"
          >
            <IconReports className="svg-sprite-icon icon-reports" />
            {t('reportsPage.pageName')}
          </NavLink>
        </li>
        <li className="menu__item">
          <NavLink
            exact
            to="/account"
            onClick={() => setSettingsIsOpen(false)}
            className="menu__link menu__link--profile"
            activeClassName="is-active"
          >
            <IconProfile className="svg-sprite-icon icon-profile" />
            {t('UserMenuPopUpWindow.account')}
          </NavLink>
        </li>
        {userIsOwnerOrAdmin && (
          <li className="menu__item">
            <NavLink
              exact
              to="/users"
              onClick={() => setSettingsIsOpen(false)}
              className="menu__link menu__link--admin"
              activeClassName="is-active"
            >
              <IconAdmin className="svg-sprite-icon icon-admin" />
              {t('UserMenuPopUpWindow.administration')}
            </NavLink>
          </li>
        )}
        {/* <li className="menu__item">
          <NavLink
            exact
            to="/faq"
            onClick={() => setSettingsIsOpen(false)}
            className="menu__link menu__link--help"
            activeClassName="is-active"
          >
            <IconHelp className="svg-sprite-icon icon-help" />
            {t('UserMenuPopUpWindow.faq')}
          </NavLink>
        </li> */}
        <li className="menu__item">
          <NavLink
            onClick={logoutHandler}
            className="menu__link menu__link--logout"
            to="/"
          >
            <IconLogout className="svg-sprite-icon icon-logout" />
            {t('UserMenuPopUpWindow.exit')}
          </NavLink>
        </li>
      </ul>
      <div className="menu__backdrop" />
    </nav>
  );
};

export default UserMenuPopUpWindow;

UserMenuPopUpWindow.propTypes = {
  userInitials: PropTypes.string,
  setSettingsIsOpen: PropTypes.func
};

UserMenuPopUpWindow.defaultProps = {
  userInitials: '',
  setSettingsIsOpen: null
};
