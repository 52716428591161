import { useState, useEffect } from 'react';
import ServiceAPI from '../services';

const useFilterByPools = () => {
  const service = new ServiceAPI();
  const filters = JSON.parse(localStorage.getItem('filters'));
  const [showFilter, setShowFilter] = useState(false);
  const [shortAllQuizzes, setShortAllQuizzes] = useState([]);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(filters?.selectedOptions || options);
  const [allPollsCheckBox, setAllPollsCheckBox] = useState(
    filters?.allPollsCheckBox || false
  );
  const [lastSearch, setLastSearch] = useState({
    quizzes: [],
    allPollsCheckBox
  });
  const [firstRenderSearch, setFirstRenderSearch] = useState(null);
  const [devices, setDevices] = useState([]);

  const allOptions = (items) =>
    items.map((item) => {
      return { value: item.id, label: item.title };
    });

  useEffect(() => {
    // Get all polls for filter by polls
    service.getAllShortQuizzes({ is_active: true }).then((resp) => {
      if (resp.status === 200) {
        setShortAllQuizzes(resp.data);
        setFirstRenderSearch({
          quizzes: allOptions(resp.data),
          allPollsCheckBox: false
        });
      }
    });
  }, []);

  useEffect(() => {
    // Set options and selected all polls in first render
    setOptions(allOptions(shortAllQuizzes));
    selected.length === 0 && setSelected(allOptions(shortAllQuizzes));
    setLastSearch({
      ...lastSearch,
      quizzes: filters?.selectedOptions || allOptions(shortAllQuizzes)
    });
  }, [shortAllQuizzes]);

  useEffect(() => {
    const selectedQuizesIds = selected.map((item) => item.value);
    const devicesIdsAgg = [];

    shortAllQuizzes.forEach((item) => {
      if (selectedQuizesIds.some((i) => i === item.id)) {
        item.devices.forEach((e) => devicesIdsAgg.push(e.id));
      }
    });
    setDevices(devicesIdsAgg);
  }, [selected, shortAllQuizzes]);

  const changeOrganization = (e) => {
    e === null ? setSelected([]) : setSelected(e);
  };

  const showAllPolls = () => {
    // Button Cansel
    setAllPollsCheckBox(false);
    setSelected(allOptions(shortAllQuizzes));
  };

  return {
    showFilter,
    setShowFilter,
    shortAllQuizzes,
    options,
    selected,
    allPollsCheckBox,
    setAllPollsCheckBox,
    changeOrganization,
    showAllPolls,
    lastSearch,
    setLastSearch,
    firstRenderSearch,
    devices
  };
};

export default useFilterByPools;
