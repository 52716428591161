import React from 'react';
import PropTypes from 'prop-types';
import logoSended from '../../img/qr-sended.png';
import QrFirmLogo from './QrFirmLogo';
import QrFooter from './QrFooter';
import translation from './translation.json';
import logoBase from '../../img/qr-logo-base.png';

const ThanksOrAlreadyVoteQr = ({ alreadyVote, lang, image, buttonClicked }) => {
  const thanks = alreadyVote
    ? translation.thanks[lang]
    : translation.thanks[lang];
  const message = alreadyVote
    ? translation.alreadyVote[lang]
    : translation.thanksWithOptions[lang][buttonClicked];

  const openLinkInNewTab = () => {
    window.open('https://liketo.me', '_blank');
  };

  return (
    <>
      <div className="container text-center">
        <QrFirmLogo image={image} />
        <img className="page-qr__sended-img" src={logoSended} alt="" />
        <h1 className="page-qr__sended-title">{thanks}</h1>
        <div className="page-qr__sended-subtitle-container">
          <p className="page-qr__sended-subtitle">
            {message}
            <br />
            {translation.haveANiceDay[lang]}
          </p>
        </div>
      </div>
      <div className="page-qr__feedback-container">
        <p className="page-qr__feedback-text page-qr__buttom-logo">
          {translation.sendFeedbackWith[lang]}{' '}
          <a href="https://liketo.me/" target="_blank" rel="noreferrer">
            <img src={logoBase} alt="liketo.me" />
          </a>
        </p>
        <p className="page-qr__feedback-text">
          {translation.wantToCollect[lang]}
        </p>
        <button
          onClick={openLinkInNewTab}
          className="btn btn-demo btn-get-started"
        >
          {translation.getStarted[lang]}
        </button>
      </div>
      <QrFooter developedIn={translation.developedIn[lang]} />
    </>
  );
};

export default ThanksOrAlreadyVoteQr;

ThanksOrAlreadyVoteQr.propTypes = {
  alreadyVote: PropTypes.bool,
  lang: PropTypes.string,
  image: PropTypes.shape({}),
  buttonClicked: PropTypes.string
};

ThanksOrAlreadyVoteQr.defaultProps = {
  alreadyVote: false,
  lang: 'en',
  image: null,
  buttonClicked: null
};
