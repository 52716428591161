// import * as jwtDecode from 'jwt-decode';
import jwt_decode from 'jwt-decode';
/* eslint-disable no-return-await  */
/* eslint-disable no-underscore-dangle  */

const GET = 'GET';
const POST = 'POST';
const PUT = 'PUT';
const PATCH = 'PATCH';
const DELETE = 'DELETE';
const CONTENT_TYPE_JSON = { 'Content-Type': 'application/json' };

export const getInfoFromToken = (key) => {
  try {
    const token = localStorage.getItem('token');
    const decodedToken = jwt_decode(token);
    return decodedToken[key];
  } catch (error) {
    return false;
  }
};

function isTokenExp() {
  const exp = getInfoFromToken('exp');
  const date = new Date();
  const timestamp = Math.floor(date.getTime() / 1000);
  return exp < timestamp;
}

function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
}

function noNeedTokenForURL(url) {
  const noNeedTokenURLs = ['policy', 'terms', 'reset-password', 'vote', 'errorPage', 'login'];
  return noNeedTokenURLs.some(i => url.includes(i))
}

async function request(
  url,
  params,
  method = GET,
  headers = CONTENT_TYPE_JSON,
  tokenPre = 'Bearer'
) {
  const token = isTokenExp() ? '' : localStorage.getItem('token');
  if (token) {
    headers.Authorization = `${tokenPre} ${token.replace(/^"(.*)"$/, '$1')}`;
  }
  const options = {
    method,
    headers
  };

  // if params exists and method is GET, add query string to url
  // otherwise, just add params as a "body" property to the options object
  if (params) {
    if (method === GET) {
      url += `?${objectToQueryString(params)}`;
    } else if (params instanceof FormData) {
      options.body = params;
    } else {
      options.body = JSON.stringify(params); // body should match Content-Type in headers option
    }
  }
  let status = null;
  let data = null;
  let body = null;
  let reader = null;
  let header = null;

  if (url.includes('download')) {
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        reader = response;
        header = response.headers;
        try {
          status = response.status;
        } catch (e) {
          status = response.status;
        }
      }
      if ([400, 403, 500].includes(response.status)) {
        let result;
        try {
          result = await response.json();
        } catch (e) {
          status = response.status;
          data = response.statusText;
          body = response.body;
        }
        status = response.status;
        data = result;
      }
    } catch (e) {
      console.log(`FETCH ERROR: ${e}`);
    }
    console.log({ status, reader, header, data });
    return { status, reader, header, data };
  }

  try {
    const response = await fetch(url, options);
    if (response.ok) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        status = response.status;
        data = response.statusText;
        body = response.body;
      }
      status = response.status;
      data = result;
    }
    if (
      process.env.REACT_APP_SERVER !== 'https://apitest.liketo.me' &&
      process.env.REACT_APP_SERVER !== 'http://localhost:8000' &&
      [404, 500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510].includes(
        response.status
      )
    ) {
      window.location.href = '/errorPage';
    }
    if (response.status === 401 && 
      !noNeedTokenForURL(window.location.href) &&
      !JSON.parse(localStorage.getItem('tokenRefresh'))) {
      localStorage.clear();
      window.location.href = '/';
    }
    if ([400, 403].includes(response.status)) {
      let result;
      try {
        result = await response.json();
      } catch (e) {
        status = response.status;
        data = response.statusText;
        body = response.body;
      }
      status = response.status;
      data = result;
    }
  } catch (e) {
    console.log(`FETCH ERROR: ${e}`);
  }
  // console.log({ status, data, body });
  return { status, data, body };
}

export default class ServiceAPI {
  constructor() {
    this.server = process.env.REACT_APP_SERVER;
    this.api = process.env.REACT_APP_API;
    this.apiUrl = `${this.server}${this.api}`;
  }

  async _refresh() {
    localStorage.removeItem('token');
    return await request(
      `${this.apiUrl}/refresh/`,
      {
        refresh: JSON.parse(localStorage.getItem('tokenRefresh'))
      },
      POST
    );
  }

  async _request(url, params, METHOD, headers) {
    if (isTokenExp()  && !noNeedTokenForURL(url)) {
      const token = await this._refresh();
      if (token.status === 400) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return false;
      }

      try {
        await new Promise((resolve) => {
          localStorage.setItem('token', JSON.stringify(token.data.access));
          resolve();
        });
      } catch (e) {
        localStorage.clear();
      }
    }

    return await request(url, params, METHOD, headers);
  }

  async login(params) {
    return await this._request(`${this.apiUrl}/login/`, params, POST);
  }

  async confirmResetPassword(params) {
    return await this._request(
      `${this.apiUrl}/users/confirm-reset-password/`,
      params,
      POST
    );
  }

  async getUserInfo() {
    return await this._request(
      `${this.apiUrl}/users/${getInfoFromToken('user_id')}/`,
      null,
      GET
    );
  }

  async changeUserInfo(params) {
    return await this._request(
      `${this.apiUrl}/users/${getInfoFromToken('user_id')}/`,
      params,
      PATCH
    );
  }

  async changeUserPassword(params) {
    return await this._request(
      `${this.apiUrl}/users/${getInfoFromToken('user_id')}/password/`,
      params,
      PUT
    );
  }

  async getUsers(params) {
    return await this._request(
      `${this.apiUrl}/users/${getInfoFromToken('user_id')}/guests/`,
      params,
      GET
    );
  }

  async getGuestUser(guestId) {
    return await this._request(
      `${this.apiUrl}/users/${getInfoFromToken('user_id')}/guests/${guestId}/`,
      null,
      GET
    );
  }

  async updateGuestUser(guestId, params) {
    return await this._request(
      `${this.apiUrl}/users/${getInfoFromToken('user_id')}/guests/${guestId}/`,
      params,
      PATCH
    );
  }

  async addGuestUser(params) {
    return await this._request(
      `${this.apiUrl}/users/${getInfoFromToken('user_id')}/guests/`,
      params,
      POST
    );
  }

  async deleteGuestUser(guestId) {
    return await this._request(
      `${this.apiUrl}/users/${getInfoFromToken('user_id')}/guests/${guestId}/`,
      null,
      DELETE
    );
  }

  async getDevices(params) {
    return await this._request(`${this.apiUrl}/devices/`, params, GET);
  }

  async getSingleDevice(deviceId) {
    return await this._request(
      `${this.apiUrl}/devices/${deviceId}/`,
      null,
      GET
    );
  }

  async updateSingleDevice(deviceId, params) {
    return await this._request(
      `${this.apiUrl}/devices/${deviceId}/`,
      params,
      PATCH
    );
  }

  async deleteSingleDevice(deviceId) {
    return await this._request(
      `${this.apiUrl}/devices/${deviceId}/`,
      null,
      DELETE
    );
  }

  async resetUserPassword(params) {
    return await this._request(
      `${this.apiUrl}/users/reset-password/`,
      params,
      POST
    );
  }

  async getPolls(params) {
    return await this._request(`${this.apiUrl}/quizzes/`, params, GET);
  }

  async getSinglePoll(pollId) {
    return await this._request(`${this.apiUrl}/quizzes/${pollId}/`, null, GET);
  }

  async changePoll(pollId, params) {
    return await this._request(
      `${this.apiUrl}/quizzes/${pollId}/`,
      params,
      PATCH
    );
  }

  async createPoll(params) {
    return await this._request(
      `${this.apiUrl}/quizzes/quiz_question/`,
      params,
      POST
    );
  }

  async getAllShortQuizzes(params) {
    return await this._request(
      `${this.apiUrl}/quizzes/short_quizzes/`,
      params,
      GET
    );
  }

  async getReports(params) {
    return await this._request(`${this.apiUrl}/reports/`, params, GET);
  }

  async getSingleReport(reportId) {
    return await this._request(
      `${this.apiUrl}/reports/${reportId}/`,
      null,
      GET
    );
  }

  async createReport(params) {
    return await this._request(`${this.apiUrl}/reports/`, params, POST);
  }

  async updateReport(reportId, params) {
    return await this._request(
      `${this.apiUrl}/reports/${reportId}/`,
      params,
      PATCH
    );
  }

  async deleteReport(reportId) {
    return await this._request(
      `${this.apiUrl}/reports/${reportId}/`,
      null,
      DELETE
    );
  }

  async exportReport(reportId) {
    return await this._request(
      `${this.apiUrl}/reports/${reportId}/download/`,
      null,
      GET
    );
  }

  async getRaitingsByQuizzes(params) {
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await this._request(`${this.apiUrl}/ratings-quiz/`, { ...params, time_zone }, GET);
  }

  async getRaitingsByAddress(params) {
    return await this._request(`${this.apiUrl}/ratings-address/`, params, GET);
  }

  async getRatingsView(params) {
    return await this._request(`${this.apiUrl}/rating-view/`, params, GET);
  }

  async getQrVote(id) {
    return await this._request(`${this.apiUrl}/vote/${id}/`, null, GET);
  }

  async qrVote(params) {
    return await this._request(`${this.apiUrl}/vote/`, params, POST);
  }

  async additionalQrVote(params) {
    return await this._request(`${this.apiUrl}/vote/answers/`, params, POST);
  }

  async qrVoteQuestionAnswers(params) {
    return await this._request(
      `${this.apiUrl}/quizzes/${params.quizId}/questions/${params.questionId}/polls/${params.pollId}/`,
      params.data,
      GET
    );
  }

  async qrVoteQuestionComments(params) {
    return await this._request(
      `${this.apiUrl}/quizzes/${params.quizId}/questions/${params.questionId}/polls/${params.pollId}/poll_questions/${params.commentsId}/answers/`,
      params.data,
      GET
    );
  }

  async sendServiceEmail(params) {
    return await this._request(
      `${this.apiUrl}/users/send-service-email/`,
      params,
      POST
    );
  }

  async getReviews(params) {
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await this._request(
      `${this.apiUrl}/google-review/`,
      { ...params, time_zone },
      GET
    );
  }
}
