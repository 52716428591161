import React from 'react';
import PropTypes from 'prop-types';
import logoSended from '../../img/qr-sended.png';
import QrFirmLogo from './QrFirmLogo';
import QrFooter from './QrFooter';
import translation from './translation.json';

const ThanksOrAlreadyVoteQr = ({ alreadyVote, lang, image, buttonClicked }) => {
  const thanks = alreadyVote
    ? translation.thanks[lang]
    : translation.thanksWithOptions[lang][buttonClicked];
  const message = alreadyVote
    ? translation.alreadyVote[lang]
    : translation.successVote[lang];
  return (
    <>
      <div className="container text-center">
        <QrFirmLogo image={image} />
        <img className="page-qr__sended-img" src={logoSended} alt="" />
        <h1 className="page-qr__sended-title">{thanks}</h1>
        <p className="page-qr__sended-subtitle">
          {message}
          <br />
          {!alreadyVote && (
            <>
              {translation.thanksWithOptionsMessage[lang][buttonClicked]}
              <br />
            </>
          )}
          {translation.haveANiceDay[lang]}
        </p>
      </div>
      <QrFooter developedIn={translation.developedIn[lang]} />
    </>
  );
};

export default ThanksOrAlreadyVoteQr;

ThanksOrAlreadyVoteQr.propTypes = {
  alreadyVote: PropTypes.bool,
  lang: PropTypes.string,
  image: PropTypes.shape({}),
  buttonClicked: PropTypes.string
};

ThanksOrAlreadyVoteQr.defaultProps = {
  alreadyVote: false,
  lang: 'en',
  image: null,
  buttonClicked: null
};
