import React, { useState, useEffect, useReducer, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ServiceAPI from '../../services';
import {
  changeUserInfo,
  firstName,
  lastName,
  email,
  error
} from '../../actions';
import {
  validateNameLength,
  validateEmailPattern
} from '../../usefulFunctions';
import reducer, { initialState } from '../../reducers/changeUserInfo';
import { ReactComponent as IconConfirm } from '../../img/sprite/svg/confirm.svg';
import MainButton from '../Buttons/MainButton';
import DefaultInner from '../../Layouts/DefaultInner';
import GlobalContext from '../../context/globalContext';
import DeleteButton from '../Buttons/DeleteButton';
import DeleteReportModal from '../Modals/DeleteReportModal';

const UserEdit = (props) => {
  const { createNewUser } = props;
  const { userId } = useParams();
  const service = new ServiceAPI();
  const { t } = useTranslation();
  const { stateGlobal } = useContext(GlobalContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const notify = () => toast(t('ChangesSaves.changesIsSaved'));
  const userAlreadyExistNotify = () => toast.error(t('UserEdit.userAlreadyExist'));
  const deleteNotify = () => toast(t('reportsPage.deleteSuccess'));
  const [makeAdmin, setMakeAdmin] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userCanDelete =
    stateGlobal.user?.is_owner ||
    (stateGlobal.user?.is_admin &&
      state.isAdmin === false &&
      state.isOwner === false);

  useEffect(() => {
    if (!createNewUser) {
      service.getGuestUser(userId).then((resp) => {
        if (resp.status === 200) {
          dispatch(changeUserInfo(resp.data));
          setMakeAdmin(resp.data.is_admin);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const validateFirstNameField = (value) => {
    dispatch(
      error({
        requiredFirstName: !value,
        lengthFirstName: !validateNameLength(value, 3, 254)
      })
    );
  };

  const validateLastNameField = (value) => {
    dispatch(
      error({
        requiredLastName: !value,
        lengthLastName: !validateNameLength(value, 3, 254)
      })
    );
  };

  const validateEmailField = (value) => {
    dispatch(
      error({
        requiredEmail: !value,
        emailPattern: !validateEmailPattern(value)
      })
    );
  };

  const validateFields = () => {
    validateFirstNameField(state.firstName.value);
    validateLastNameField(state.lastName.value);
    validateEmailField(state.email.value);
    return (
      !(
        state.errors.requiredFirstName ||
        state.errors.requiredLastName ||
        state.errors.requiredEmail ||
        state.errors.emailPattern
      ) &&
      state.firstName.value &&
      state.lastName.value &&
      state.email.value
    );
  };

  const handlerOnChangeField = (e, func) => {
    dispatch(func(e.target.value));
  };

  const handlerSubmit = () => {
    if (!validateFields()) return false;
    if (!createNewUser) {
      service
        .updateGuestUser(userId, {
          first_name: state.firstName.value,
          last_name: state.lastName.value,
          email: state.email.value,
          is_admin: makeAdmin
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(
              changeUserInfo({
                first_name: resp.data.first_name,
                last_name: resp.data.last_name,
                email: resp.data.email,
                firm: resp.data.firm
              })
            );
            notify();
            // redirect back to administration page
            history.push('/users');
          }
        });
    } else {
      service
        .addGuestUser({
          first_name: state.firstName.value,
          last_name: state.lastName.value,
          email: state.email.value,
          is_admin: makeAdmin
        })
        .then((resp) => {
          if (resp.status === 201) {
            notify();
            // redirect back to administration page
            history.push('/users');
          } else if (resp.status === 400 && resp?.data?.message === 'user already exists') {
            userAlreadyExistNotify();
          }
        });
    }
  };

  const deleteGuestUser = () => {
    service.deleteGuestUser(userId).then((resp) => {
      if (resp.status === 204) {
        deleteNotify();
        history.push('/users');
      }
    });
  };

  return (
    <DefaultInner>
      <div className="page-layout__content">
        <section className="content">
          <div className="content__container">
            <div className="content__header">
              <h1 className="content__title">
                {createNewUser
                  ? t('UserEdit.inviteYourColegs')
                  : t('UserEdit.edit')}
              </h1>
              {userCanDelete && (
                <DeleteButton
                  text={t('UserEdit.delete')}
                  openModal={() => setShowDeleteModal(true)}
                />
              )}
            </div>
            <div className="content__box">
              <div className="user-form">
                <div className="user-form__conatiner">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handlerSubmit(e.target);
                    }}
                  >
                    <div className="form-group mb-4">
                      <label htmlFor="email">
                        <span className="required-label">*</span>
                        Email
                      </label>
                      <input
                        autoComplete="off"
                        className={
                          state.email.touched &&
                          !state.errors.requiredEmail &&
                          !state.errors.emailPattern
                            ? 'form-control is-valid'
                            : state.errors.requiredEmail ||
                              state.errors.emailPattern
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        id="email"
                        type="text"
                        name="email"
                        value={state.email.value}
                        onChange={(e) => {
                          handlerOnChangeField(e, email);
                          validateEmailField(e.target.value);
                        }}
                      />
                      {state.errors.requiredEmail && (
                        <div className="error">
                          <span>{t('ERROR_TEXT.requiredEmail')}</span>
                        </div>
                      )}
                      {state.errors.emailPattern &&
                        !state.errors.requiredEmail && (
                          <div className="error">
                            <span>{t('ERROR_TEXT.validEmail')}</span>
                          </div>
                        )}
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="firstName">
                        <span className="required-label">*</span>
                        {t('UserEdit.firstName')}
                      </label>
                      <input
                        autoComplete="off"
                        className={
                          state.firstName.touched &&
                          !state.errors.requiredFirstName
                            ? 'form-control is-valid'
                            : state.errors.requiredFirstName
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        id="firstName"
                        type="text"
                        name="firstName"
                        value={state.firstName.value}
                        onChange={(e) => {
                          handlerOnChangeField(e, firstName);
                          validateFirstNameField(e.target.value);
                        }}
                      />
                      {state.errors.requiredFirstName && (
                        <div className="error">
                          <span>{t('ERROR_TEXT.requiredFirstName')}</span>
                        </div>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="lastName">
                        <span className="required-label">*</span>
                        {t('UserEdit.lastName')}
                      </label>
                      <input
                        autoComplete="off"
                        className={
                          state.lastName.touched &&
                          !state.errors.requiredLastName
                            ? 'form-control is-valid'
                            : state.errors.requiredLastName
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={state.lastName.value}
                        onChange={(e) => {
                          handlerOnChangeField(e, lastName);
                          validateLastNameField(e.target.value);
                        }}
                      />
                      {state.errors.requiredLastName && (
                        <div className="error">
                          <span>{t('ERROR_TEXT.requiredLastName')}</span>
                        </div>
                      )}
                    </div>
                    {stateGlobal.user?.is_owner && (
                      <div className="form-group has-page-switch mb-5">
                        <span className="page-switch-label">
                          {t('UserEdit.makeAdministrator')}
                        </span>
                        <label className="page-switch">
                          <input
                            type="checkbox"
                            name="admin"
                            checked={makeAdmin}
                            onChange={() => {
                              setMakeAdmin(!makeAdmin);
                            }}
                          />
                          <span className="page-switch__slider" />
                        </label>
                      </div>
                    )}
                    <div className="form-group has-submit-btn mb-0">
                      <MainButton
                        type="submit"
                        className="btn btn-confirm has-icon"
                        text={t('UserEdit.save')}
                      >
                        <span className="icon-btn">
                          <IconConfirm className="svg-sprite-icon icon-confirm" />
                        </span>
                      </MainButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <DeleteReportModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        deleteFunc={deleteGuestUser}
        modalTitleText={t('UserEdit.deleteUserModal')}
      />
    </DefaultInner>
  );
};

export default UserEdit;

UserEdit.propTypes = {
  createNewUser: PropTypes.bool
};

UserEdit.defaultProps = {
  createNewUser: false
};
