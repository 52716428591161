import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceAPI from '../../services';
import GlobalContext from '../../context/globalContext';
import Loading from '../Loading/Loading';
import { loading } from '../../actions';
import PageContent from '../StyleComponents/PageContent';
import SingleReview from './SingleReview';
import { Map } from 'react-lodash';
import device from '../../img/app-device.png';
import ReviewDownloadModal from '../Modals/ReviewDownloadModal';
import Pagination from '../Pagination/Pagination';
import usePagination from '../../hooks/usePagination';

const Reviews = () => {
  const { t } = useTranslation();
  const service = new ServiceAPI();
  const { dispatchGlobal, stateGlobal } = useContext(GlobalContext);

  const [reviews, setReviews] = useState([]);
  const [showReviewDownloadModal, setShowReviewDownloadModal] = useState(false);
  const [singleReviewToDownload, setSingleReviewToDownload] = useState(null);
  const [count, setCount] = useState(0);
  const paginationNum = process.env.REACT_APP_PAGINATION_NUM;

  const {
    next,
    prev,
    jump,
    currentPage,
    setCurrentPage,
    maxPage
  } = usePagination(count, paginationNum);

  useEffect(() => {
    let mounted = true;
    dispatchGlobal(loading(true));
    if (mounted) {
      service.getReviews({page_size: paginationNum}).then((resp) => {
        if (resp.status === 200) {
          setCount(resp.data.count);
          setReviews(resp.data.results);
          dispatchGlobal(loading(false));
        }
      });
    }
    return () => (mounted = false);
  }, []);

  const downloadReviewModal = (
    author,
    rating,
    date,
    snippet,
    backgroundColor,
    firstLetter
  ) => {
    setShowReviewDownloadModal(true);
    setSingleReviewToDownload({
      author,
      rating,
      date,
      snippet,
      backgroundColor,
      firstLetter
    });
  };

  const changePage = (pageNum) => {
    if (currentPage !== pageNum) {
      dispatchGlobal(loading(true));
      const paginationObj = {
        page: pageNum,
        page_size: paginationNum
      };
      service.getReviews(paginationObj).then((resp) => {
        if (resp.status === 200) {
          const allGoogleReviews = [];
          resp.data.results.map((item) =>
            allGoogleReviews.push(item)
          );
          setReviews(allGoogleReviews);
          dispatchGlobal(loading(false));
        }
      });
    }
  };

  return (
    <PageContent>
      <div className="content__header">
        <h1 className="content__title">{t('ReviewsPage.pageName')}</h1>
      </div>

      {stateGlobal.isLoading ? (
        <Loading />
      ) : reviews.length === 0 ? (
        <div className="statistics-start">
          <div className="statistics-start__container">
            <img src={device} alt="" />
            <h2 className="statistics-start__title">
              {t('ReviewsPage.noReviews')}
            </h2>
          </div>
        </div>
      ) : (
        <div className="reviews__panels">
          <Map
            collection={reviews}
            iteratee={(item) => {
              return (
                <div className="single-review" key={item.id}>
                  <SingleReview
                    key={item.id}
                    id={item.id}
                    author={item.author}
                    rating={item.rating}
                    date={item.date}
                    snippet={item.snippet}
                    downloadReviewModal={downloadReviewModal}
                  />
                </div>
              );
            }}
          />
        </div>
      )}
      <div className="content__pagination content__pagination--has-btn">
        <Pagination
          currentPage={currentPage}
          maxPage={maxPage}
          next={next}
          prev={prev}
          jump={jump}
          changePage={changePage}
        />
      </div>
      <ReviewDownloadModal
        show={showReviewDownloadModal}
        onHide={() => setShowReviewDownloadModal(false)}
        author={singleReviewToDownload?.author}
        rating={singleReviewToDownload?.rating}
        date={singleReviewToDownload?.date}
        snippet={singleReviewToDownload?.snippet}
        backgroundColor={singleReviewToDownload?.backgroundColor}
        firstLetter={singleReviewToDownload?.firstLetter}
      />
    </PageContent>
  );
};

export default Reviews;
